<template>
    
    <select class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  :placeholder="getPlaceHolder" v-model="getValue">
        <option selected="" disabled> Select {{fieldData.label.replace("Select", "") }} </option>
        <option :value="option" v-for="option,idx in getOptions" :key="idx">{{option}}</option>
    </select>
</template>

<script>
export default {
 props: {
    fieldData: {
      type: Object,
      require: true
    }
  },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getPlaceHolder() {
            return this.fieldData.placeholder || ''
        },
        getValue: {
            get() {
                return this.fieldData.value || ''
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.form-select {
    -moz-padding-start: calc(.75rem - 3px);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right .75rem center;
    background-size: 16px 12px;
}
</style>